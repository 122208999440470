import React from 'react';
import { ThemeProvider } from './theme-context';

function App ({ children }) {
  return (
  <ThemeProvider>
    {children}
  </ThemeProvider>
)};

export default App;